<template>
  <div id="home">
    <img alt="Vue logo" src="../assets/logo.png" class="logo" />
    <h1>SteadyGrowth AI</h1>
    <p class="tagline">Find out how SteadyGrowth AI can take phone calls for your business, book appointments, and more.</p>
    
    <div class="button-container">
      <router-link to="/travel" class="sample-button">Travel</router-link>
      <router-link to="/saas" class="sample-button">SaaS</router-link>
      <router-link to="/creative" class="sample-button">Creative</router-link>
      <router-link to="/auto" class="sample-button">Auto</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
}
</script>

<style scoped>
#home {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #35495e;
  margin-top: 60px;
}

.logo {
  width: 100px;
  margin-bottom: 20px;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
  color: #40b883;
}

.tagline {
  font-size: 1.2em;
  color: #35495e;
  margin-bottom: 100px;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.sample-button {
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: #40b883;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.sample-button:hover {
  background-color: #35495e;
}
</style>
