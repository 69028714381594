<template>
  <button @click="handleClick" class="voice-call-button">
    <div class="button-content">
      <div class="title">{{ isPlaying ? 'Stop' : 'Demo' }}</div>
      <div class="subtitle">{{ isPlaying ? 'Click to Stop Demo' : 'Click to Play Demo' }}</div>
    </div>
  </button>
  <ModalForm v-if="showModal" @formValidated="handleFormValidated" @closeModal="handleCloseModal" />
</template>

<script>
import Vapi from "@vapi-ai/web";
import ModalForm from './ModalForm.vue'; // Adjust path as needed

export default {
  name: 'VoiceCallButton',
  components: { ModalForm },
  props: {
    assistantId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isPlaying: false,
      showModal: false,
      hasSubmittedInfo: localStorage.getItem('hasSubmittedInfo') === 'true',
    };
  },
  created() {
    this.vapi = new Vapi(process.env.VUE_APP_VAPI_API_KEY);
  },
  methods: {
    handleClick() {
      if (!this.hasSubmittedInfo) {
        this.showModal = true;
      } else {
        this.toggleCall();
      }
    },
    toggleCall() {
      if (!this.isPlaying) {
        this.vapi.start(this.assistantId);
        this.isPlaying = true;
      } else {
        this.vapi.stop();
        this.isPlaying = false;
      }
    },
    handleFormValidated() {
      this.hasSubmittedInfo = true;
      localStorage.setItem('hasSubmittedInfo', 'true');
      this.showModal = false;
      this.toggleCall();
    },
    handleCloseModal() {
      this.showModal = false;
    }
  }
}
</script>

<style>
.voice-call-button {
  width: 200px;
  height: 200px;
  background-color: white;
  border: none;
  padding: 20px;
  font-size: 1.2em;
  cursor: pointer;
  border-radius: 50%;
  transition: transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: radial-gradient(circle, white 60%, #40b883);
}

.voice-call-button::before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border: 5px solid;
  border-color: #40b883 #35495e;
  border-radius: 50%;
  background: linear-gradient(135deg, #40b883, #35495e);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.button-content {
  position: relative;
  text-align: center;
}

.title {
  font-size: 2em;
  color: #40b883;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 0.7em;
  color: #35495e;
}

.voice-call-button:hover {
  transform: scale(1.05);
}
</style>