<template>
    <div id="app">
      <VoiceCallButton :assistantId="'e37c8aaf-562d-4f52-a5fb-e94f5a7581b7'" />
    </div>
  </template>
  
  <script>
  import VoiceCallButton from '../components/VoiceCallButton.vue'
  
  export default {
    name: 'App',
    components: {
      VoiceCallButton
    }
  }
  </script>
  
  <style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #35495e;
    margin-top: 60px;
  }
  
  .logo {
    width: 100px;
    margin-bottom: 20px;
  }
  
  h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
    color: #40b883;
  }
  
  .description {
    font-size: 1.2em;
    color: #35495e;
    margin-bottom: 100px;
  }
  </style>
  