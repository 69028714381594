import { createApp } from 'vue'
import App from './App.vue'

import { createWebHistory, createRouter } from 'vue-router'

import HomePage from './pages/HomePage.vue'
import TravelVai from './pages/TravelVai.vue'
import SaasVai from './pages/SaasVai.vue'
import PhotoVai from './pages/PhotoVai.vue'
import AutoVai from './pages/AutoVai.vue'
import StaycationVai from './pages/StaycationVai.vue'
import SalesMindVai from './pages/SalesMindVai.vue'
import SalesMindExport from './pages/SalesMindExport.vue'

const routes = [
  { path: '/', component: HomePage },
  { path: '/travel', component: TravelVai },
  { path: '/saas', component: SaasVai },
  { path: '/creative', component: PhotoVai },
  { path: '/auto', component: AutoVai },
  { path: '/destinationchannel', component: StaycationVai },
  { path: '/salesmindai', component: SalesMindVai },
  { path: '/sme', component: SalesMindExport },

]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

const app = createApp(App)

app.use(router)
app.mount('#app')
