<template>
  <div id="app">
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #35495e;
  margin-top: 60px;
}

.logo {
  width: 100px;
  margin-bottom: 20px;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
  color: #40b883;
}

.description {
  font-size: 1.2em;
  color: #35495e;
  margin-bottom: 100px;
}
</style>
