<template>
    <div id="app">
      <img alt="Vue logo" src="../assets/logo.png" class="logo">
      <h1> Steady Growth AI Agent</h1>
      <p class="description">Empower your travel business with seamless AI-powered voice calls.</p>
      <VoiceCallButton :assistantId="'7bf81816-03a0-4918-918f-a8e2cece1c23'" />
    </div>
  </template>
  
  <script>
  import VoiceCallButton from '../components/VoiceCallButton.vue'
  
  export default {
    name: 'App',
    components: {
      VoiceCallButton
    }
  }
  </script>
  
  <style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #35495e;
    margin-top: 60px;
  }
  
  .logo {
    width: 100px;
    margin-bottom: 20px;
  }
  
  h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
    color: #40b883;
  }
  
  .description {
    font-size: 1.2em;
    color: #35495e;
    margin-bottom: 100px;
  }
  </style>
  