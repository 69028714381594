<template>
  <div class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <form @submit.prevent="submitForm" class="form-container">
        <p class="form-instructions">Please submit your information to get access to the demo.</p>
        <div class="form-group">
          <label for="name">Name:</label>
          <input type="text" v-model="name" id="name" required>
        </div>
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" v-model="email" id="email" required>
        </div>
        <div class="form-group">
          <label for="phone">Phone Number:</label>
          <input type="tel" v-model="phone" id="phone" required>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  </div>
</template>

<script>
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default {
  props: ['showModal'],
  data() {
    return {
      name: '',
      email: '',
      phone: '',
    };
  },
  methods: {
    async submitForm() {
      try {
        await addDoc(collection(db, 'formData'), {
          name: this.name,
          email: this.email,
          phone: this.phone
        });
        alert('Form submitted successfully!');
        this.$emit('formValidated');
        this.closeModal();
      } catch (error) {
        console.error(error);
        alert('There was an error submitting the form.');
      }
    },
    closeModal() {
      this.$emit('closeModal');
    }
  },
};
</script>

<style>
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  border-radius: 8px;
  text-align: left;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.form-container {
  text-align: left;
  padding: 20px;
}

.form-instructions {
  font-size: 0.9em;
  color: #888;
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input {
  padding: 10px;
  font-size: 1em;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: #40b883;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"] {
  margin-top: 10px;
  background-color: #35495e;
}

button[type="submit"]:hover {
  background-color: #2c3e50;
}
</style>
